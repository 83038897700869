import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';

function TransferToken() {
  const { contractAddress } = useParams();
  const [toAddress, setToAddress] = useState('');
  const [amount, setAmount] = useState('');

  const handleTransfer = async (event) => {
    event.preventDefault();
    try {
      const response = await axios.post(`https://token.dev.deltixhub.com/transaction/transfer`, {}, 
      {
        params: {
        contractAddress: contractAddress,
        toAddress: toAddress,
        amount: amount
      }
    }
    );
      alert(`Transfer successful: ${response.data}`);
    } catch (error) {
      alert('Transfer failed: ' + error.message);
    }
  };

  return (
    <div className="transfer">
      <h2>Transfer Tokens</h2>
      <form onSubmit={handleTransfer}>
        <input type="text" value={toAddress} onChange={e => setToAddress(e.target.value)} placeholder="To Address" />
        <input type="number" value={amount} onChange={e => setAmount(e.target.value)} placeholder="Amount" />
        <button type="submit">Transfer</button>
      </form>
    </div>
  );
}

export default TransferToken;
