import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';

function CheckBalance() {
  const { contractAddress } = useParams();
  const [clientAddress, setClientAddress] = useState('');
  const [balance, setBalance] = useState(null);

  const handleCheckBalance = async (event) => {
    event.preventDefault();
    try {
      const response = await axios.get(`https://token.dev.deltixhub.com/token/getBalance`, {
        params: { clientAddress, contractAddress }
      });
      setBalance(response.data);
    } catch (error) {
      console.error('Failed to fetch balance:', error);
      alert('Error fetching balance');
    }
  };

  return (
    <div className="balance">
      <h2>Check Balance</h2>
      <form onSubmit={handleCheckBalance}>
        <input
          type="text"
          value={clientAddress}
          onChange={(e) => setClientAddress(e.target.value)}
          placeholder="Enter your address"
          required
        />
        <button type="submit">Check</button>
      </form>
      {balance !== null && (
        <p>Balance: {balance}</p>
      )}
    </div>
  );
}

export default CheckBalance;
