import React, { useState } from 'react';
import axios from 'axios';

function CreateToken() {
    const [tokenData, setTokenData] = useState({
        name: '',
        symbol: '',
        initialSupply: 0
    });

    const handleChange = (e) => {
        setTokenData({ ...tokenData, [e.target.name]: e.target.value });
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        const params = new URLSearchParams({
            name: tokenData.name,
            symbol: tokenData.symbol,
            initialSupply: tokenData.initialSupply
        }).toString();
    
        try {
            const response = await axios.post(`https://token.dev.deltixhub.com/token/deploy?${params}`);
            alert('Token deployment initiated: ' + response.data);
        } catch (error) {
            alert('Error deploying token: ' + error.message);
        }
    };
    
    

    return (
        <div className="token-form">
            <h2>Create New Token</h2>
            <form onSubmit={handleSubmit}>
                <label>
                    Token Name:
                    <input type="text" name="name" value={tokenData.name} onChange={handleChange} placeholder="Enter token name" />
                </label>
                <label>
                    Symbol:
                    <input type="text" name="symbol" value={tokenData.symbol} onChange={handleChange} placeholder="Enter token symbol" />
                </label>
                <label>
                    Initial Supply:
                    <input type="number" name="initialSupply" value={tokenData.initialSupply} onChange={handleChange} placeholder="Enter initial supply" />
                </label>
                <button type="submit">Create Token</button>
            </form>
        </div>
    );
}

export default CreateToken;
