import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';


function TokenList() {
  const [tokens, setTokens] = useState([]);

  useEffect(() => {
    const fetchTokens = async () => {
      try {
        const response = await axios.get('https://token.dev.deltixhub.com/token/');
        setTokens(response.data);
      } catch (error) {
        console.error('Failed to fetch tokens:', error);
      }
    };

    fetchTokens();
  }, []);

  return (
    <div className="token-list">
      <h2>Available Tokens</h2>
      <div className="card-container">
        {tokens.map(token => (
          <div className="card" key={token.id}>
            <div className="card-body">
              <h5 className="card-title">{token.name} ({token.symbol})</h5>
              <h6 className="card-subtitle">Supply: {token.totalSupply}</h6>
              <h6 className="card-subtitle">Token Address: {token.address}</h6>
              <div className="card-actions">
                <Link to={`/check-balance/${token.address}`} className="btn">Balance</Link>
                <Link to={`/transfer-token/${token.address}`} className="btn">Transfer</Link>
                <Link to={`/mint-token/${token.address}`} className="btn btn-mint">Mint</Link>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default TokenList;
