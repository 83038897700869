import React from 'react';
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';
import TokenList from './components/TokenList';
import CheckBalance from './components/CheckBalance';
import TransferToken from './components/TransferToken';
import CreateToken from './components/CreateToken'; // Import the component
import './App.css';
import MintToken from './components/MintToken';

function App() {
  return (
    <Router>
      <div className="app">
        <header>
          <h1>Token Management Dashboard</h1>
          <nav>
            <Link to="/">Home</Link>
            <Link to="/create-token">Create Token</Link>  
          </nav>
        </header>
        <main>
          <Routes>
            <Route path="/" element={<TokenList />} />
            <Route path="/create-token" element={<CreateToken />} /> 
            <Route path="/check-balance/:contractAddress" element={<CheckBalance />} />
            <Route path="/transfer-token/:contractAddress" element={<TransferToken />} />
            <Route path="/mint-token/:contractAddress" element={<MintToken />} />
          </Routes>
        </main>
      </div>
    </Router>
  );
}

export default App;
