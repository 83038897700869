import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';

function MintToken() {
  const { contractAddress } = useParams();
  console.log("Rendering MintToken, contract address:", contractAddress);
  const [mintData, setMintData] = useState({
    amount: ''
  });

  const handleChange = (e) => {
    setMintData({ ...mintData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (event) => {
    console.log(contractAddress)
    event.preventDefault();
    try {
      const response = await axios.post('https://token.dev.deltixhub.com/token/mint', {}, {
    params: {
        contractAddress: contractAddress,
        amount: mintData.amount
    }
});

      alert('Minting initiated: ' + response.data);
    } catch (error) {
      alert('Error minting tokens: ' + error.message);
    }
  };

  return (
    <div className='mint-form'>
      <h2>Mint Tokens</h2>
      <p>If you see this message, routing is working!</p>
      <form onSubmit={handleSubmit}>
        <input
          type="number"
          name="amount"
          value={mintData.amount}
          onChange={handleChange}
          placeholder="Amount to mint"
          required
        />
        <button type="submit">Mint Tokens</button>
      </form>
    </div>
  );
}

export default MintToken;
